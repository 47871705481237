import React, { useState } from 'react';

const ServiceCategory = () => {
    const categories = [
        {
            title:"Social Media Marketing",
            content:"Targeted campaigns designed to build your online community and drive engagement across key platforms like Facebook, Instagram, LinkedIn, and Twitter. Our strategies focus on increasing brand awareness, fostering meaningful connections with your audience, and driving measurable interaction through engaging content."
        },
        {
            title:"SEO Optimization",
            content:"Improve your website’s search rankings to increase visibility and attract more customers. Our SEO strategies are designed to enhance your site's performance on search engines, driving organic traffic and ensuring your business is easily found by potential clients."
        },
        {
            title:"PPC Advertising",
            content:"Data-driven pay-per-click strategies designed to maximize your ROI and deliver fast results. Utilize platforms like Google My Business and Meta Ads to reach your target audience quickly and efficiently, ensuring that every click brings you closer to your business goals."
        },
        {
            title:"Content Creation",
            content:"Engaging content that tells your story and connects with your audience. From compelling blogs and eye-catching videos to impactful graphics, we craft content that resonates with your target market and strengthens your brand presence."
        }
    ]
    const [openIndex, setOpenIndex] = useState(null);

    const toggleAccordion = (index) => {
        if (openIndex === index) {
            // If clicked on an already open accordion, close it
            setOpenIndex(null);
        } else {
            // Open the clicked accordion and close others
            setOpenIndex(index);
        }
    };

    return (
        <div>
            {categories.map((category, index) => (
                <div
                    key={index}
                    className={`ServiceCategory_accordion__fV3tg ${
                        openIndex === index ? 'ServiceCategory_accordion--expanded__z8ldx' : ''
                    }`}
                >
                    <div className="ServiceCategory_title__5ZA_G" onClick={() => toggleAccordion(index)} style={{ cursor: 'pointer' }}>
                        <h3>{category.title}</h3>
                    </div>
                    <div className="ServiceCategory_icon__QeftP" onClick={() => toggleAccordion(index)} style={{ cursor: 'pointer' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48">
                            <path stroke="#202322" strokeWidth="2" d="M7 24h34M24 41V7" vectorEffect="non-scaling-stroke"></path>
                        </svg>
                    </div>
                    {openIndex === index && (
                        <div className="ServiceCategory_content__QZMAK">
                            <div className="ServiceCategory_content__inner__e_1ae">
                                <p>{category.content}</p>
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default ServiceCategory;
