import React from 'react';
import Header from './components/Header/Header';
import { Routes, Route } from 'react-router-dom';
import Home from './components/Home/Home';
import Footer from './components/Footer/Footer'; 
import 'font-awesome/css/font-awesome.min.css';
// import ScrollToTop from './components/UI/ScrollToTop';
import About from './components/About/About';
import Services from './components/Services/Services';
import Contact from './components/Contact/Contact';
import Insights from './components/Insights/Insights';
function App() {
  return (
    <>
      {/* <ScrollToTop/> */}
      <Header />
      <Routes>
        <Route exact path='/' element={<Home/>} />
        <Route path='/about' element={<About/>}/>
        <Route path='/services' element={<Services/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/insights' element={<Insights/>} />
      </Routes>
      <Footer/> 
      </>
  );
}

export default App; 

