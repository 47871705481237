import Cta from "../Cta/Cta";
import classes from "./Contact.module.css";

const Contact = () => {
    return (
        <main>
            <section className="Hero_section__tLa6b">
                <div className="Hero_section__container__skzZH">
                    <h1>Get in<span className="highlight_orange"> Touch</span></h1>
                </div>
            </section>
            <section className="Contacts_section__66flU">
                <div className="Contacts_container__Vl3QD">
                    <h2>Got Questions? <strong >Reach out.</strong></h2>
                </div>
                <div className="Contacts_flex_wrapper__JvZqm">
                    <div className="ContactForm_wrapper__Wms_s">
                        <picture>
                            <img
                                alt="Amanda Morrissey"
                                loading="lazy"
                                decoding="async"
                                data-nimg="fill"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    left: "0",
                                    top: "0",
                                    right: "0",
                                    bottom: "0",
                                    color: "transparent",
                                }}
                                sizes="(max-width: 900px) 100vw, 50vw"
                                srcSet="/images/banner/contact.webp?w=384&q=100 384w, 
                                /images/banner/contact.webp?w=640&q=100 640w, 
                                /images/banner/contact.webp?w=750&q=100 750w, 
                                /images/banner/contact.webp?w=828&q=100 828w, 
                                /images/banner/contact.webp?w=1080&q=100 1080w, 
                                /images/banner/contact.webp?w=1200&q=100 1200w, 
                                /images/banner/contact.webp?w=1920&q=100 1920w, 
                                /images/banner/contact.webp?w=2048&q=100 2048w, 
                                /images/banner/contact.webp?w=3840&q=100 3840w"
                                src="/images/banner/contact.webp?w=3840&q=100"
                            />
                        </picture>
                    </div>
                    <div className="Contacts_container__Vl3QD">
                        <div className="Hero_section__container__content__4aq71">
                            <div className="Hero_section__container__content__item___YgjR">
                                <p className="subheading">Write to Us: <a className={classes.linktext} href="mailto:info@digiicreators.com"> info@digiicreators.com</a></p>
                            </div>
                            <div className="Hero_section__container__content__item___YgjR">
                                <p className="subheading">Call Us: <a className={classes.linktext} href="tel:+918126312322"> +91 81263 12322</a></p>
                            </div>
                        </div>
                        <div className={`col-lg-12 ml-lg-auto ${classes.socicon}`}>
                            <div className="row">
                                <div className="col-lg-6 col-md-3 col-sm-6 col-6 text-center">
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="mailto:info@digiicreators.com"
                                        className={classes.fbicon}
                                    >
                                        <i className="fa fa fa-envelope" />
                                    </a>
                                </div>
                                <div className="col-lg-6 col-md-3 col-sm-6 col-6 text-center">
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://ig.me/m/digiicreators"
                                        className={classes.instaicon}
                                    >
                                        <i className="fa fa-instagram" />
                                    </a>
                                </div>
                                <div className="col-lg-6 col-md-3 col-sm-6 col-6 text-center">
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://api.whatsapp.com/send?phone=918126312322&text=Hi%20DigiiCreator%20team"
                                        className={classes.twiticon}
                                    >
                                        <i className="fa fa-whatsapp" />
                                    </a>
                                </div>
                                <div className="col-lg-6 col-md-3 col-sm-6 col-6 text-center">
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.linkedin.com/company/digii-creators/"
                                        className={classes.linkind}
                                    >
                                        <i className="fa fa-linkedin" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Cta />
        </main>
    )
}
export default Contact;