import Cta from "../Cta/Cta";

const Services = () => {
    return (
        <main>
            <section className="Hero_section__44LhZ">
                <picture>
                    <img
                        alt="Services hero image"
                        decoding="async"
                        data-nimg="fill"
                        style={{
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            left: "0",
                            top: "0",
                            right: "0",
                            bottom: "0",
                            color: "transparent",
                        }}
                        sizes="100vw"
                        srcSet="/images/banner/services.jpg?amp;w=640&q=100 640w, 
                                /images/banner/services.jpg?amp;w=750&q=100 750w, 
                                /images/banner/services.jpg?amp;w=828&q=100 828w, 
                                /images/banner/services.jpg?amp;w=1080&q=100 1080w, 
                                /images/banner/services.jpg?amp;w=1200&q=100 1200w, 
                                /images/banner/services.jpg?amp;w=1920&q=100 1920w, 
                                /images/banner/services.jpg?amp;w=2048&q=100 2048w, 
                                /images/banner/services.jpg?amp;w=3840&q=100 3840w"
                        src="/images/banner/services.jpg?w=3840&q=100"
                    />
                </picture>
                <div className="Hero_container__ax0Ol">
                    <div className="Hero_content__5kbKS">
                        <h1>
                            Empowering Brands with Global <span className="highlight_orange">Digital Solutions</span>
                        </h1>
                        <p>
                            Empathy fuels our design. We believe media experiences, built with understanding, can enrich
                            lives. Imagine marketing that seamlessly integrates, not intrudes. That's the human-centered
                            future we create.
                        </p>
                    </div>
                </div>
            </section>

            <section className="Services_section__PHV_G">
                <div className="Services_container__APNxm">
                    <div className="Services_category__ndVEB">
                        <h2>Social Media Marketing</h2>
                        <div className="Services_category__content__qJHmA">
                            <p>
                                Targeted campaigns designed to build your online community and drive engagement across key platforms like Facebook, Instagram, LinkedIn, and Twitter. Our strategies focus on increasing brand awareness, fostering meaningful connections with your audience, and driving measurable interaction through engaging content.
                            </p>
                        </div>
                    </div>

                    <div className="Services_category__ndVEB">
                        <h2>SEO Optimization</h2>
                        <div className="Services_category__content__qJHmA">
                            <p>
                                Improve your website’s search rankings to increase visibility and attract more customers. Our SEO strategies are designed to enhance your site's performance on search engines, driving organic traffic and ensuring your business is easily found by potential clients.
                            </p>
                        </div>
                    </div>

                    <div className="Services_category__ndVEB">
                        <h2>PPC Advertising</h2>
                        <div className="Services_category__content__qJHmA">
                            <p>
                                Data-driven pay-per-click strategies designed to maximize your ROI and deliver fast results. Utilize platforms like Google My Business and Meta Ads to reach your target audience quickly and efficiently, ensuring that every click brings you closer to your business goals.
                            </p>
                        </div>
                    </div>
                    <div className="Services_category__ndVEB">
                        <h2>Content Creation</h2>
                        <div className="Services_category__content__qJHmA">
                            <p>
                                Engaging content that tells your story and connects with your audience. From compelling blogs and eye-catching videos to impactful graphics, we craft content that resonates with your target market and strengthens your brand presence.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Cta/>
        </main>
    );
};

export default Services;
