import { Link } from "react-router-dom";

const Cta = () => {
    return (
        <section className="Cta_section__HXwgO">
            <div className="Cta_container___rR0R">
                <h2>
                    <strong>Accelerate </strong>
                    Your brands Growth
                </h2>
                <Link className="Link_link__Awdts Link_bg_dark__ddh8y" to="/contact">Contact Us</Link>
            </div>
        </section>
    )
}

export default Cta;