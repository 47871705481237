import Cta from "../Cta/Cta";

const About = () => {
    return (
        <main>
            <section className="Hero_section__r68Sd">
                <div className="Hero_section__container__wzkjr">
                    <h1>WE ARE DRIVEN TO <span className="highlight">IGNITE CHANGE AND SHAPE</span> TOMORROW'S FUTURE</h1>
                    <picture>
                        <img alt="About us hero" decoding="async" data-nimg="fill"
                            style={{
                                position: "absolute",
                                height: "100%",
                                width: "100%",
                                left: "0",
                                top: "0",
                                right: "0",
                                bottom: "0",
                                color: "transparent"
                            }}
                            sizes="100vw"
                            srcSet="/images/banner/3.jpg?w=640&q=100 640w, 
                                    /images/banner/3.jpg?w=750&q=100 750w, 
                                    /images/banner/3.jpg?w=828&q=100 828w, 
                                    /images/banner/3.jpg?w=1080&q=100 1080w, 
                                    /images/banner/3.jpg?w=1200&q=100 1200w, 
                                    /images/banner/3.jpg?w=1920&q=100 1920w, 
                                    /images/banner/3.jpg?w=2048&q=100 2048w, 
                                    /images/banner/3.jpg?w=3840&q=100 3840w"
                            src="/images/banner/3.jpg?w=3840&q=100"
                        />
                    </picture>
                    <p>At Digii Creators, our skilled team specializes in helping small and medium-sized businesses establish a powerful online presence. Whether you're a startup or an established brand, our mission is to bridge the gap between traditional business models and the digital world. With tailored strategies, we ensure your brand not only thrives online but also reaches the right audience. Trust us to transform your vision into a dynamic digital reality.<br /></p>
                </div>
            </section>
            <section className="President_section__VCrXd">
                <div className="President_container__AYPLW">
                    <picture>
                        <img
                            alt="Amanda Morrissey"
                            loading="lazy"
                            decoding="async"
                            data-nimg="fill"
                            style={{
                                position: "absolute",
                                height: "100%",
                                width: "100%",
                                left: "0",
                                top: "0",
                                right: "0",
                                bottom: "0",
                                color: "transparent",
                            }}
                            sizes="(max-width: 900px) 100vw, 50vw"
                            srcSet="/images/about/2.jpg?w=384&q=100 384w, 
                                /images/about/2.jpg?w=640&q=100 640w, 
                                /images/about/2.jpg?w=750&q=100 750w, 
                                /images/about/2.jpg?w=828&q=100 828w, 
                                /images/about/2.jpg?w=1080&q=100 1080w, 
                                /images/about/2.jpg?w=1200&q=100 1200w, 
                                /images/about/2.jpg?w=1920&q=100 1920w, 
                                /images/about/2.jpg?w=2048&q=100 2048w, 
                                /images/about/2.jpg?w=3840&q=100 3840w"
                            src="/images/about/2.jpg?w=3840&q=100"
                        />
                    </picture>
                    <div className="President_content__cJm_a">
                        <div className="President_text__g4UQu">
                            <span className="subheading">Driving Innovation, Building Connections</span>
                            <h2>Our Vision</h2>
                            <p>
                                To be a trailblazing marketing agency, celebrated for our creativity, excellence, and unwavering commitment to client satisfaction, while continuously setting new benchmarks in the industry.
                                <br />
                                <br />
                            </p>
                            <h2>Our Mission</h2>
                            <p>
                                To empower businesses by developing innovative social media strategies that fuel engagement, elevate brand visibility, and deliver measurable growth.
                                <br />
                                <br />
                            </p>
                            <h2>Strategic Relationships</h2>
                            <p>
                                Collaborating with influencers, content creators, client partners, and educational institutions like universities and colleges to foster innovation and growth.
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="Hero_section__r68Sd">
                <div className="Hero_section__container__wzkjr">
                    <picture>
                        <img alt="About us hero image" decoding="async" data-nimg="fill"
                            style={{
                                position: "absolute",
                                height: "100%",
                                width: "100%",
                                left: "0",
                                top: "0",
                                right: "0",
                                bottom: "0",
                                color: "transparent"
                            }}
                            sizes="100vw"
                            srcSet="/images/about/1.jpg?w=640&q=100 640w, 
                                    /images/about/1.jpg?w=750&q=100 750w, 
                                    /images/about/1.jpg?w=828&q=100 828w, 
                                    /images/about/1.jpg?w=1080&q=100 1080w, 
                                    /images/about/1.jpg?w=1200&q=100 1200w, 
                                    /images/about/1.jpg?w=1920&q=100 1920w, 
                                    /images/about/1.jpg?w=2048&q=100 2048w, 
                                    /images/about/1.jpg?w=3840&q=100 3840w"
                            src="/images/about/1.jpg?w=3840&q=100"
                        />
                    </picture>
                </div>
            </section>
            <Cta/>
        </main>
    );
};

export default About;