import { useEffect, useState } from 'react';
import MobileNavigation from './MobileNavigation';
import DesktopNavigation from './DesktopNavigation';

const Header = () => {
    const [isMobile, setIsMobile] = useState(false);

    // Check screen size and update isMobile state
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        handleResize(); // Set the initial value on mount
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            {isMobile ? <MobileNavigation /> : <DesktopNavigation />}
        </>
    );
};

export default Header;
