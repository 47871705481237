import React, { useState, useEffect } from "react";

const Insights = () => {
    const [imageFiles, setImageFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Start with loading state as true
    const [isModalOpen, setModalOpen] = useState(false);
    const [imageSrc, setImageSrc] = useState("");

    // Fetch the list of image files from the API
    useEffect(() => {
        const fetchImageFiles = async () => {
            try {
                const response = await fetch("https://admin.digiicreators.com/api.php"); // Replace with your API endpoint
                const data = await response.json();
                if (data.status === "success" && data.files.length > 0) {
                    setImageFiles(data.files);
                } else {
                    console.error("No files found.");
                }
            } catch (error) {
                console.error("Error fetching image files:", error);
            } finally {
                setIsLoading(false); // Ensure loading is false after data fetch (success or error)
            }
        };

        fetchImageFiles();
    }, []);

    // Function to open the modal with the selected image file
    const openModal = (imageFilePath) => {
        setImageSrc(imageFilePath);
        setModalOpen(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setModalOpen(false);
        setImageSrc("");
    };

    return (
        <main>
            <section className="Hero_section__T8aU_">
                <div className="Hero_section__container__kXEDK">
                    <h1>
                        End-to-end <span className="highlight">excellence</span>
                    </h1>
                    <p>
                        We are designed to deliver growth across the entire media spectrum, adapting rapidly to consumer
                        behaviour through a performance mindset.
                    </p>
                </div>
            </section>

            <section className="ListLoading_section__XsKBt">
                <div className="ListLoading_container__rAL2J">
                    <div className="ListLoading_list__Xjw9I">
                        {isLoading ? (
                            // Render shimmer effect while loading
                            Array.from({ length: 4 }).map((_, index) => (
                                <figure key={index} className="loading-placeholder">
                                    {/* Shimmer effect figure */}
                                </figure>
                            ))
                        ) : imageFiles.length > 0 ? (
                            imageFiles.map((imageFile, index) => (
                                <figure key={index} onClick={() => openModal(imageFile.path)}>
                                    <img
                                        src={imageFile.path ? imageFile.path : "/images/placeholder.png"}
                                        alt={`Thumbnail of ${imageFile.filename}`}
                                        style={{ borderRadius: "10px" }}
                                    />
                                </figure>
                            ))
                        ) : (
                            <p>No images available.</p>
                        )}
                    </div>
                </div>
            </section>

            {/* Modal to show enlarged image */}
            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}>
                            &times;
                        </span>
                        <img src={imageSrc} alt="Enlarged view" style={{ width: "100%" }} />
                    </div>
                </div>
            )}
        </main>
    );
};

export default Insights;
