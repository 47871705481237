import { useState,useLayoutEffect } from "react";
import { Link } from "react-router-dom";

const MobileNavigation = () => {
    const [isMobileNavVisible, setIsMobileNavVisible] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    useLayoutEffect(() => {
        const handleScroll = () => {
            const header = document.getElementById('header');
            if (window.scrollY < 50) {
                header?.classList.add('NavigationMobile_header--at-top__nGGU5');
                setIsScrolled(true);
            } else {
                header?.classList.remove('NavigationMobile_header--at-top__nGGU5');
                setIsScrolled(false);
            }
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const toggleMobileNav = () => {
        setIsMobileNavVisible(!isMobileNavVisible);
    };
    const closeNav = () => {
        setIsMobileNavVisible(false);
    };
    return (
        <header id="header" className={`NavigationMobile_header__zLDTP ${isScrolled ? 'NavigationMobile_header--at-top__nGGU5' : ''}`}>
            <div className="NavigationMobile_top__eK6h_">
                <Link className="NavigationMobile_header__logo__1KSTF" to="/">
                    <img src="/images/digi_creators_logo.svg" alt="Logo" />
                </Link>
                <button className="NavigationMobile_header__toggle__bn5t9" onClick={toggleMobileNav}>
                    <span>{isMobileNavVisible ? 'Close' : 'Menu'}</span>
                </button>
            </div>
            <nav className="NavigationMobile_nav__9TTXR" role="navigation" style={{ display: isMobileNavVisible ? 'block' : 'none' }} onClick={closeNav}>
                <div className="NavigationMobile_nav__container__BhDxa">
                    <div className="NavigationMobile_nav__container__links__N_Ju3">
                        <Link to="/about" onClick={closeNav}><span>About</span></Link>
                        <Link to="/services" onClick={closeNav}><span>Services</span></Link>
                        <Link to="/insights" onClick={closeNav}><span>Insights</span></Link>
                    </div>
                    <Link className="NavigationMobile_nav__container__contact__lE8Oa" to="/contact" onClick={closeNav}><span>Get in touch</span></Link>
                </div>
            </nav>
            {isMobileNavVisible && (
                <div className={`NavigationMobile_background__i2E_i ${isMobileNavVisible ? 'active' : ''}`} onClick={closeNav}></div>
            )}
        </header>
    );
}
export default MobileNavigation;



