
import { Link } from "react-router-dom";
import { useState,useLayoutEffect } from "react";
const DesktopNavigation = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useLayoutEffect(() => {
        // const handleScroll = () => {
        //     const header = document.getElementById('header');
        //     if (window.scrollY < 50) {
        //         header?.classList.add('NavigationDesktop_header--at-top__NB1rN');
        //         setIsScrolled(true);
        //     } else {
        //         header?.classList.remove('NavigationDesktop_header--at-top__NB1rN');
        //         setIsScrolled(false);
        //     }
        // };

        // // Add scroll event listener
        // window.addEventListener('scroll', handleScroll);

        // // Cleanup the event listener on component unmount
        // return () => {
        //     window.removeEventListener('scroll', handleScroll);
        // };
    }, []);
    return (
        <header id="header" className={`NavigationDesktop_header__8LOe_ ${isScrolled ? 'NavigationDesktop_header--at-top__NB1rN' : ''}`}>
            <nav className="NavigationDesktop_nav__wh2Lt" role="navigation">
                <Link className="NavigationDesktop_header__logo__cl_ZE" to="/">
                    <img src="/images/digi_creators_logo.svg" alt="Logo" />
                </Link>
                <Link className="NavigationDesktop_link__5wvNe" to="/about"><span>About</span></Link>
                <Link className="NavigationDesktop_link__5wvNe" to="/services"><span>Services</span></Link>
                {/* <Link className="NavigationDesktop_link__5wvNe" to="/work"><span>Work</span></Link> */}
                <Link className="NavigationDesktop_link__5wvNe" to="/insights"><span>Insights</span></Link>
                <Link className="NavigationDesktop_contact__znOgB" to="/contact"><span>Get in touch</span></Link>
            </nav>
        </header>
    );
}
export default DesktopNavigation;
