
import './Footer.css';
const Footer = () => {
    const socials = [
        // {
        //   icon: "/images/icon/twitter-md.svg",
        //   link: "#",
        // },
        // {
        //   icon: "/images/icon/facebook-md.svg",
        //   link: "#",
        // },
        {
          icon: "/images/icon/linkdien-md.svg",
          link: "https://www.linkedin.com/company/digii-creators/",
        },
        {
          icon: "/images/icon/instagram-md.svg",
          link: "https://ig.me/m/digiicreators",
        },
        {
          icon: "/images/icon/whatsapp-md.svg",
          link: "https://api.whatsapp.com/send?phone=918126312322&text=Hi%20DigiiCreator%20team",
        },
      ];
    const footer = {
        logo: "/images/digi_creators_full_logo.svg",
        title: "",
        copyrightText: "© Digiicreators 2024,",
        companyName: "",
        copyrightLink: "",
        widgets: [
          {
            title: "Company",
            menus: [
                {
                    name: "Home",
                    link: "/",
                  },
              {
                name: "About",
                link: "about",
              },

              {
                name: "Services",
                link: "services",
              },
              {
                name: "Insights",
                link: "insights",
              },
      
            ],
          },
          {
            title: "Contact Us",
            menus: [
              {
                icon: "/images/footor/mail-dark.webp",
                name: "info@digiicreators.com",
                link: "mailto:info@digiicreators.com",
              },
              {
                icon: "/images/footor/phone-dark.webp",
                name: "+91 81263 12322",
                link: "tel:+918126312322",
              },
              {
                icon: "/images/footor/phone-dark.webp",
                name: "+91 82678 05291",
                link: "tel:+918267805291",
              }
              // {
              //   icon: "/images/footor/location-dark.webp",
              //   name: "104, first floor Eldeco elegant Vibhuti khand Lucknow 226010",
              // },
            ],
          },
        ],
      }
    return (
        <footer className="footer__area">
        <div className="footer__socail-wrap">
          <div className="footer__social-inner">
            {/* <div className="footer__btn d-flex align-items-center gap-3">
              {footer.title && (
                <h3 className="footer__app-title">{footer.title}</h3>
              )}
            </div> */}
            {/* <!-- Footer social  -->  */}
            {socials && socials.length > 0 && (
              <ul className="footer__socail">
                {socials.map((social, index) => (
                  <li key={index} className="footer__social-item">
                    <a className="footer__social-link" href={social.link} target="_blank" rel="noopener noreferrer">
                      <img src={social.icon} alt="Icon" />
                    </a>
                  </li>
                ))}
              </ul>
            )}
            {/* <!-- Footer social  --> */}
          </div>
        </div>
        <div className="footer__btm">
          {/* <!-- Footer widget  --> */}
  
          <div className="footer__widget">
          <div className="footer__logo">
              <a href="/">
                <img src={footer.logo} alt="Logo" />
              </a>
            </div>
            <h3 className="footer__widget-title">
              <span>Your Creative Partner in the Digital Age</span>
            </h3>
          </div>
  
          {/* <!-- Footer widget  --> */}
          {footer.widgets &&
            footer.widgets.map((widget, index) => (
              <div key={index} className="footer__widget">
                {widget.title && (
                  <h3 className="footer__widget-title">{widget.title}</h3>
                )}
                {widget.menus && widget.menus.length > 0 && (
                  <ul className="footer__menu">
                    {widget.menus.map((menu, idx) => (
                      <li
                        key={idx}
                        className={`${
                          menu.icon ? "footer__info-item" : "footer__menu-item"
                        }`}
                      >
                        {menu.icon && (
                          <span className="footer__info-icon">
                            <img src={menu.icon} alt="Icon" />
                          </span>
                        )}
                        {menu.link ? (
                          <a href={menu.link}>{menu.name}</a>
                        ) : (
                          menu.name
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
        </div>
        <div className="footer__copyright text-center">
          <p className="footer__copyright-text">
            {footer.copyrightText}
            <a href={footer.copyrightLink}>{footer.companyName}</a>
          </p>
        </div>
      </footer>
    );
}
export default Footer;